export const STORE_META = 'STORE_META'
export const STORE_PAGINATION = 'STORE_PAGINATION'
export const STORE_CURRENCY = 'STORE_CURRENCY'
export const STORE_COURSE_GROUP = 'STORE_COURSE_GROUP'
export const STORE_STATISTIC = 'STORE_STATISTIC'
export const STORE_IMPORTANT_CITIES = 'STORE_IMPORTANT_CITIES'
export const STORE_TESTIMONIAL = 'STORE_TESTIMONIAL'
export const STORE_FAQS = 'STORE_FAQS'
export const STORE_BLOG_POSTS = 'STORE_BLOG_POSTS'
export const STORE_FOOTER = 'STORE_FOOTER'
export const STORE_WORLD_COUNTRIES = 'STORE_WORLD_COUNTRIES'
export const STORE_MEGA_MENU = 'STORE_MEGA_MENU'
export const STORE_MOBILE_MENU_STATE = 'STORE_MOBILE_MENU_STATE'
export const STORE_INTERNAL_LINKS = 'STORE_INTERNAL_LINKS'
export const STORE_BREADCRUMB = 'STORE_BREADCRUMB'
export const STORE_SEO_CONTENT = 'STORE_SEO_CONTENT'
export const STORE_BEST_TEACHERS = 'STORE_BEST_TEACHERS'
export const STORE_COURSES = 'STORE_COURSES'
export const STORE_COURSES_PARENT = 'STORE_COURSES_PARENT'
export const UPDATE_COURSES_LOADING_LIST = 'UPDATE_COURSES_LOADING_LIST'
export const UPDATE_COURSES_TYPE = 'UPDATE_COURSES_TYPE'
export const UPDATE_COURSE_ID = 'UPDATE_COURSE_ID'
export const UPDATE_COURSES_SEARCH_TERM = 'UPDATE_COURSES_SEARCH_TERM'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const STORE_HOME_START_TEACHING = 'STORE_HOME_START_TEACHING'
export const UPDATE_LIST_QUERIES = 'UPDATE_LIST_QUERIES'
export const UPDATE_LIST_PARAMS = 'UPDATE_LIST_PARAMS'
export const RESET_LIST_QUERIES = 'RESET_LIST_QUERIES'
export const RESET_LIST_PARAMS = 'RESET_LIST_PARAMS'
export const STORE_LIST_SCHEMA_RATING = 'STORE_LIST_SCHEMA_RATING'
export const STORE_LIST_PRICE_CHART = 'STORE_LIST_PRICE_CHART'
export const STORE_LIST_TEACHERS = 'STORE_LIST_TEACHERS'
export const PUSH_LIST_TEACHERS = 'PUSH_LIST_TEACHERS'
export const STORE_LIST_FILTERS = 'STORE_LIST_FILTERS'
export const STORE_LIST_DESIGN_COLOR = 'STORE_LIST_DESIGN_COLOR'
export const STORE_LIST_TITLE = 'STORE_LIST_TITLE'
export const STORE_LIST_SORTS = 'STORE_LIST_SORTS'
export const STORE_TUTOR_SELECTED = 'STORE_TUTOR_SELECTED'
export const STORE_TUTOR_SELECT = 'STORE_TUTOR_SELECT'
export const STORE_TUTOR_CALENDAR = 'STORE_TUTOR_CALENDAR'
export const STORE_TUTOR_RESUME = 'STORE_TUTOR_RESUME'
export const STORE_TUTOR_COURSES = 'STORE_TUTOR_COURSES'
export const STORE_TUTOR_COURSE_GROUP = 'STORE_TUTOR_COURSE_GROUP'
export const STORE_SHOW_TEACHERS_COUNT = 'STORE_SHOW_TEACHERS_COUNT'
export const STORE_SHOW_SORTS = 'STORE_SHOW_SORTS'
export const STORE_SHOW_TOTAL = 'STORE_SHOW_TOTAL'
export const STORE_TUTOR_FEEDBACKS = 'STORE_TUTOR_FEEDBACKS'
export const STORE_REQUEST_TEACHER = 'STORE_REQUEST_TEACHER'
export const STORE_REQUEST_CALENDAR = 'STORE_REQUEST_CALENDAR'
export const STORE_REQUEST_CALENDAR_STEP = 'STORE_REQUEST_CALENDAR_STEP'
export const STORE_REQUEST_PRICE_STEP = 'STORE_REQUEST_PRICE_STEP'
export const STORE_REQUEST_LANGUAGES_STEP = 'STORE_REQUEST_LANGUAGES_STEP'
export const STORE_REQUEST_DESCRIPTION_STEP = 'STORE_REQUEST_DESCRIPTION_STEP'
export const STORE_REQUEST_TRACKING = 'STORE_REQUEST_TRACKING'
export const STORE_REQUEST_SELECT_TUTOR = 'STORE_REQUEST_SELECT_TUTOR'
export const STORE_REQUEST_CONFIRM = 'STORE_REQUEST_CONFIRM'
export const STORE_REQUEST_FORM = 'STORE_REQUEST_FORM'
export const STORE_REQUEST_FINISH = 'STORE_REQUEST_FINISH'
// start teaching
export const STORE_STEP_STATUS = 'STORE_STEP_STATUS'
export const STORE_PERSONAL_INFO = 'STORE_PERSONAL_INFO'
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO'
export const STORE_LANGUAGES = 'STORE_LANGUAGES'
export const STORE_TEACHING_COURSES = 'STORE_TEACHING_COURSES'
export const STORE_TEACHING_PRICE = 'STORE_TEACHING_PRICE'
export const STORE_EDUCATION_DEGREES = 'STORE_EDUCATION_DEGREES'
export const STORE_WORK_EXPERIENCES = 'STORE_WORK_EXPERIENCES'
export const STORE_CERTIFICATES = 'STORE_CERTIFICATES'
export const STORE_PROFILE_PICTURE = 'STORE_PROFILE_PICTURE'
export const STORE_TEACHER_ABOUT = 'STORE_TEACHER_ABOUT'
export const STORE_INTRO_VIDEO = 'STORE_INTRO_VIDEO'
export const STORE_WORK_TIME = 'STORE_WORK_TIME'
export const STORE_DOCUMENTS = 'STORE_DOCUMENTS'
export const STORE_TRACKING = 'STORE_TRACKING'
export const STORE_TICKETS = 'STORE_TICKETS'
export const STORE_TICKET = 'STORE_TICKET'

// Live chat
export const STORE_CHAT_LIST = 'STORE_CHAT_LIST'
export const STORE_UNREAD_MESSAGE = 'STORE_UNREAD_MESSAGE'
export const STORE_NEW_MESSAGE = 'STORE_NEW_MESSAGE'
export const STORE_MESSAGE_LIST = 'STORE_MESSAGE_LIST'
export const STORE_LIVE_CHAT_VISIBILITY = 'STORE_LIVE_CHAT_VISIBILITY'
export const STORE_LIVE_CHAT_LOADING = 'STORE_LIVE_CHAT_LOADING'
export const STORE_MESSAGE_LIST_CONVERSATION_SELECTED = 'STORE_MESSAGE_LIST_CONVERSATION_SELECTED'
export const UPDATE_CHAT_LIST_UNREAD_MESSAGE = 'UPDATE_CHAT_LIST_UNREAD_MESSAGE'
export const STORE_STREAM_MESSAGE = 'STORE_STREAM_MESSAGE'
export const STORE_MORE_MESSAGE_LOADING = 'STORE_MORE_MESSAGE_LOADING'
export const STORE_PRESENCE_STATUS = 'STORE_PRESENCE_STATUS'
// Profile

export const STORE_TEACHER_REQUEST_LATEST = 'STORE_TEACHER_REQUEST_LATEST'
export const STORE_TEACHER_REQUESTS = 'STORE_TEACHER_REQUESTS'
export const STORE_PRIVATE_CLASSES_LATEST = 'STORE_PRIVATE_CLASSES_LATEST'
export const STORE_PRIVATE_CLASSES = 'STORE_PRIVATE_CLASSES'
export const STORE_PRIVATE_CLASSES_FEEDBACK = 'STORE_PRIVATE_CLASSES_FEEDBACK'
export const STORE_PRIVATE_CLASS = 'STORE_PRIVATE_CLASS'
export const STORE_CLASS_SESSIONS = 'STORE_CLASS_SESSIONS'
export const UPDATE_PRIVATE_CLASSES_FEEDBACK = 'UPDATE_PRIVATE_CLASSES_FEEDBACK'
export const STORE_DISCOUNT_PACK_HISTORY = 'STORE_DISCOUNT_PACK_HISTORY'
export const STORE_DISCOUNT_PACK_PURCHASE = 'STORE_DISCOUNT_PACK_PURCHASE'
export const STORE_DISCOUNT_PACK_PURCHASE_PRICE = 'STORE_DISCOUNT_PACK_PURCHASE_PRICE'
export const STORE_PAYMENT_UNPAID_SESSIONS = 'STORE_PAYMENT_UNPAID_SESSIONS'
export const STORE_BILLING_DETAILS = 'STORE_BILLING_DETAILS'
export const STORE_PAYMENT_GATEWAY_TRANSACTIONS = 'STORE_PAYMENT_GATEWAY_TRANSACTIONS'
export const STORE_PAYMENT_CALLBACK = 'STORE_PAYMENT_CALLBACK'
export const UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS'
export const STORE_TRANSACTIONS = 'STORE_TRANSACTIONS'
export const STORE_START_TEACHING_PRICE_CHART = 'STORE_START_TEACHING_PRICE_CHART'
export const STORE_TAGLINE = 'STORE_TAGLINE'
export const STORE_INTRODUCTION = 'STORE_INTRODUCTION'
