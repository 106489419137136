<template>
  <header class="header">
    <svg class="h-0 flex">
      <linearGradient id="gradient1" gradientTransform="rotate(0)">
        <stop offset="10%" stop-color="#6D70C6" />
        <stop offset="100%" stop-color="#51D5FF" />
      </linearGradient>
    </svg>
    <!--    Desktop header-->
    <HeaderDesktop
      v-if="!isTablet"
      @navigationClicked="navigationClicked"
      @userNavClicked="userNavClicked"
      @guestNavClicked="guestNavClicked"
    />
    <!--    Mobile header-->
    <HeaderMobile
      v-if="isTablet"
      @navigationClicked="navigationClicked"
      @userNavClicked="userNavClicked"
      @guestNavClicked="guestNavClicked"
    />
  </header>
</template>

<script>
import HeaderDesktop from '@design-system/section/header/header-desktop/header-desktop.vue'
import HeaderMobile from '@design-system/section/header/header-mobile/header-mobile.vue'
import detectDeviceMixin from '@helper/detectDeviceMixin'
export default {
  name: 'SectionHeader',
  components: {
    HeaderMobile,
    HeaderDesktop
  },
  mixins: [detectDeviceMixin],
  provide () {
    return {
      loggedIn: this.$auth.loggedIn,
      dropdownList: this.dropdownList,
      navigationList: this.navigationList,
      navigationListMobile: this.navigationListMobile
    }
  },
  data () {
    return {
      loggedIn: true,
      navigationList: [
        {
          name: 'Blog',
          href: this.$config.magLink,
          icon: 'import_contacts',
          meta: {
            show: !!this.$config.magLink
          }
        },
        {
          name: 'Contact us',
          href: this.$links.absolute(this.$nuxt, 'contact_us'),
          icon: 'perm_phone_msg',
          meta: {
            show: true
          }
        },
        {
          name: 'FAQ\'s',
          icon: 'forum',
          href: this.$links.absolute(this.$nuxt, 'faq'),
          meta: {
            show: true
          }
        }
      ],
      dropdownList: [
        // {
        //   icon: 'widgets',
        //   label: 'User Panel',
        //   to: ''
        // },
        // {
        //   icon: 'event_note',
        //   label: 'My requests',
        //   to: ''
        // },
        {
          icon: 'widgets',
          label: 'Profile',
          to: this.$links.absolute(this.$nuxt, 'profile_home')
        },
        {
          icon: 'logout',
          label: 'Sign out',
          to: this.localePath(this.$routes.logout.path)
        }
      ]
    }
  },
  computed: {
    navigationListMobile () {
      return [
        {
          name: 'Profile',
          href: this.$links.absolute(this.$nuxt, 'profile_home'),
          icon: 'widgets',
          meta: {
            show: !!this.$auth.loggedIn
          }
        },
        {
          name: 'Become a Tutor',
          href: this.$links.absolute(this.$nuxt, 'start_teaching'),
          icon: 'teacher',
          meta: {
            show: true,
            type: 'custom'
          }
        },
        {
          name: 'Blog',
          href: this.$config.magLink,
          icon: 'newspaper',
          meta: {
            show: !!this.$config.magLink
          }
        },
        {
          name: 'Contact us',
          href: this.$links.absolute(this.$nuxt, 'contact_us'),
          icon: 'contact_phone',
          meta: {
            show: true
          }
        },
        {
          name: 'FAQ\'s',
          icon: 'question_answer',
          href: this.$links.absolute(this.$nuxt, 'faq'),
          meta: {
            show: true
          }
        },
        {
          name: 'Sign out',
          icon: 'logout',
          href: this.$links.absolute(this.$nuxt, 'logout'),
          meta: {
            show: this.$auth.loggedIn
          }
        }
      ]
    }
  },
  methods: {
    navigationClicked (url) {
      console.log('navigation clicked', url)
    },
    userNavClicked (url) {
      console.log('user nav clicked', url)
    },
    guestNavClicked (url) {
      console.log('guest nav clicked', url)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @apply shadow-banner bg-white z-40  rounded-b-2xl p-4 sticky top-0;
}
</style>
