import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f0b06928 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _3fb3a9f9 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _23bd5410 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _2df80ca5 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4683c969 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _46e8d77f = () => interopDefault(import('../pages/UIKITS.vue' /* webpackChunkName: "pages/UIKITS" */))
const _0f949df4 = () => interopDefault(import('../pages/world.vue' /* webpackChunkName: "pages/world" */))
const _5ab2b2c0 = () => interopDefault(import('../pages/tutor-request/course.vue' /* webpackChunkName: "pages/tutor-request/course" */))
const _753ee9cc = () => interopDefault(import('../pages/tutor-request/form/index.vue' /* webpackChunkName: "pages/tutor-request/form/index" */))
const _59f835a4 = () => interopDefault(import('../pages/tutor-request/calendar/_id.vue' /* webpackChunkName: "pages/tutor-request/calendar/_id" */))
const _1d815db6 = () => interopDefault(import('../pages/tutor-request/confirm/_id.vue' /* webpackChunkName: "pages/tutor-request/confirm/_id" */))
const _aae7251c = () => interopDefault(import('../pages/tutor-request/description/_id.vue' /* webpackChunkName: "pages/tutor-request/description/_id" */))
const _27e158c3 = () => interopDefault(import('../pages/tutor-request/finish/_id.vue' /* webpackChunkName: "pages/tutor-request/finish/_id" */))
const _415ce351 = () => interopDefault(import('../pages/tutor-request/languages/_id.vue' /* webpackChunkName: "pages/tutor-request/languages/_id" */))
const _c8a6c002 = () => interopDefault(import('../pages/tutor-request/price/_id.vue' /* webpackChunkName: "pages/tutor-request/price/_id" */))
const _0de47fa7 = () => interopDefault(import('../pages/tutor-request/tracking/_id.vue' /* webpackChunkName: "pages/tutor-request/tracking/_id" */))
const _88bdca9a = () => interopDefault(import('../pages/tutor/_name.vue' /* webpackChunkName: "pages/tutor/_name" */))
const _c6cf679e = () => interopDefault(import('../dynamic-pages/tutor-list.vue' /* webpackChunkName: "" */))
const _c6f3e998 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4cc0f2ae = () => interopDefault(import('../../config/pages/auth/forget-password.vue' /* webpackChunkName: "pages/auth/forget-password" */))
const _16a9dc17 = () => interopDefault(import('../../config/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _615e85c7 = () => interopDefault(import('../../config/pages/auth/login-by-oauth.vue' /* webpackChunkName: "pages/auth/login-by-oauth" */))
const _0ba2af4c = () => interopDefault(import('../../config/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _d75d9830 = () => interopDefault(import('../../config/pages/auth/set-password.vue' /* webpackChunkName: "pages/auth/set-password" */))
const _0aeb93da = () => interopDefault(import('../../config/pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _286bda8a = () => interopDefault(import('../../config/pages/auth/verify.vue' /* webpackChunkName: "pages/auth/verify" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en/about-us",
    component: _f0b06928,
    name: "about-us___en"
  }, {
    path: "/en/contact-us",
    component: _3fb3a9f9,
    name: "contact-us___en"
  }, {
    path: "/en/faq",
    component: _23bd5410,
    name: "faq___en"
  }, {
    path: "/en/privacy-policy",
    component: _2df80ca5,
    name: "privacy-policy___en"
  }, {
    path: "/en/terms",
    component: _4683c969,
    name: "terms___en"
  }, {
    path: "/en/UIKITS",
    component: _46e8d77f,
    name: "UIKITS___en"
  }, {
    path: "/en/world",
    component: _0f949df4,
    name: "world___en"
  }, {
    path: "/en/tutor-request/course",
    component: _5ab2b2c0,
    name: "tutor-request-course___en"
  }, {
    path: "/en/tutor-request/form",
    component: _753ee9cc,
    name: "tutor-request-form___en"
  }, {
    path: "/en/tutor-request/calendar/:id?",
    component: _59f835a4,
    name: "tutor-request-calendar-id___en"
  }, {
    path: "/en/tutor-request/confirm/:id?",
    component: _1d815db6,
    name: "tutor-request-confirm-id___en"
  }, {
    path: "/en/tutor-request/description/:id?",
    component: _aae7251c,
    name: "tutor-request-description-id___en"
  }, {
    path: "/en/tutor-request/finish/:id?",
    component: _27e158c3,
    name: "tutor-request-finish-id___en"
  }, {
    path: "/en/tutor-request/languages/:id?",
    component: _415ce351,
    name: "tutor-request-languages-id___en"
  }, {
    path: "/en/tutor-request/price/:id?",
    component: _c8a6c002,
    name: "tutor-request-price-id___en"
  }, {
    path: "/en/tutor-request/tracking/:id?",
    component: _0de47fa7,
    name: "tutor-request-tracking-id___en"
  }, {
    path: "/en/tutor/:name?",
    component: _88bdca9a,
    name: "tutor-name___en"
  }, {
    path: "/en/tutor-list/:param1?/:param2?/:param3?/:param4?/:param5?",
    component: _c6cf679e,
    name: "tutor-list___en"
  }, {
    path: "/",
    component: _c6f3e998,
    name: "index"
  }, {
    path: "/en/auth/forget-password",
    component: _4cc0f2ae,
    name: "auth-forget-password___en"
  }, {
    path: "/en/auth/login",
    component: _16a9dc17,
    name: "auth-login___en"
  }, {
    path: "/en/auth/login-by-oauth",
    component: _615e85c7,
    name: "auth-login-by-oauth___en"
  }, {
    path: "/en/auth/logout",
    component: _0ba2af4c,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/set-password",
    component: _d75d9830,
    name: "auth-set-password___en"
  }, {
    path: "/en/auth/signup",
    component: _0aeb93da,
    name: "auth-signup___en"
  }, {
    path: "/en/auth/verify",
    component: _286bda8a,
    name: "auth-verify___en"
  }, {
    path: "/fr/auth/forget-password",
    component: _4cc0f2ae,
    name: "auth-forget-password___fr"
  }, {
    path: "/fr/auth/login",
    component: _16a9dc17,
    name: "auth-login___fr"
  }, {
    path: "/fr/auth/login-by-oauth",
    component: _615e85c7,
    name: "auth-login-by-oauth___fr"
  }, {
    path: "/fr/auth/logout",
    component: _0ba2af4c,
    name: "auth-logout___fr"
  }, {
    path: "/fr/auth/set-password",
    component: _d75d9830,
    name: "auth-set-password___fr"
  }, {
    path: "/fr/auth/signup",
    component: _0aeb93da,
    name: "auth-signup___fr"
  }, {
    path: "/fr/auth/verify",
    component: _286bda8a,
    name: "auth-verify___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
