import Vue from 'vue'
import BaseAppLogo from '@app/design-system/base/app/app-logo.vue'
import BaseButton from '@app/design-system/base/button/button.vue'
import BaseIcon from '@app/design-system/base/icon/icon.vue'
import IconWithBG from '@app/design-system/base/icon/icon-bg.vue'
import BaseTextField from '@app/design-system/base/form/text-field.vue'
import BaseTextarea from '@app/design-system/base/form/textarea.vue'
import BaseSearchField from '@app/design-system/base/form/search-field.vue'
import BaseToggleButton from '@app/design-system/base/form/toggle-button.vue'
import BaseRadioButton from '@app/design-system/base/form/radio-button.vue'
import BaseRadioButtonGroup from '@app/design-system/base/form/radio-button-group.vue'
import BaseSelectbox from '@app/design-system/base/form/select-box.vue'
import BaseCheckbox from '@app/design-system/base/form/check-box.vue'
import BaseBadge from '@app/design-system/base/badge/badge.vue'
import BaseCard from '@app/design-system/base/card/card.vue'
import BaseDialog from '@app/design-system/base/dialog/dialog.vue'
import BaseListItem from '@app/design-system/base/list-item/list-item.vue'
import BaseDropdown from '@app/design-system/base/dropdown/dropdown.vue'
import BaseDrawer from '@app/design-system/base/drawer/drawer.vue'
import BaseTag from '@app/design-system/base/tag/tag.vue'
import BaseTab from '@app/design-system/base/tab/tab.vue'
import BaseExpansion from '@app/design-system/base/expansion/expansion.vue'
import BaseExpansionPanelOld from '@app/design-system/base/expansion/expansion-panel.old.vue'
import BaseExpansionPanel from '@app/design-system/base/expansion/expansion-panel.vue'
import BaseTitleSection from '@app/design-system/base/title/title-section.vue'
import BaseExpansionText from '@app/design-system/base/expansion/expansion-text.vue'
import BaseExpansionDiv from '@app/design-system/base/expansion/expansion-div.vue'
import BaseCardFixBottom from '@app/design-system/base/card/card-fix-bottom.vue'
import BlockLoadingBlur from '@app/design-system/block/loading/loading-blur.vue'
import BaseLink from '@app/design-system/base/link/link.vue'
import BaseTooltip from '@app/design-system/base/tooltip/tooltip.vue'
import BaseImg from '@app/design-system/base/img/img-source.vue'
import BaseSeparator from '@app/design-system/base/separator/separator.vue'
import BaseAvatarTeacher from '@app/design-system/base/avatar/avatar-teacher.vue'
const components = {
  BaseAppLogo,
  BaseButton,
  BaseIcon,
  IconWithBG,
  BaseTextField,
  BaseTextarea,
  BaseSelectbox,
  BaseCheckbox,
  BaseToggleButton,
  BaseRadioButton,
  BaseRadioButtonGroup,
  BaseBadge,
  BaseCard,
  BaseDialog,
  BaseListItem,
  BaseDropdown,
  BaseDrawer,
  BaseTag,
  BaseTab,
  BaseExpansion,
  BaseExpansionPanel,
  BaseExpansionPanelOld,
  BaseTitleSection,
  BaseExpansionText,
  BaseSearchField,
  BaseExpansionDiv,
  BaseCardFixBottom,
  BlockLoadingBlur,
  BaseLink,
  BaseTooltip,
  BaseImg,
  BaseSeparator,
  BaseAvatarTeacher
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
